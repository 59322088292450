import { render, staticRenderFns } from "./Pomegranate.vue?vue&type=template&id=823664de&scoped=true&"
import script from "./Pomegranate.vue?vue&type=script&lang=js&"
export * from "./Pomegranate.vue?vue&type=script&lang=js&"
import style0 from "./Pomegranate.vue?vue&type=style&index=0&id=823664de&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "823664de",
  null
  
)

export default component.exports