<template>
    <transition appear @enter="onEnter">
        <svg
            id="pomegranate"
            ref="pomegranate"
            xmlns="http://www.w3.org/2000/svg"
            xml:space="preserve"
            :width="size"
            :height="size"
            version="1.1"
            style="shape-rendering:geometricPrecision; text-rendering:geometricPrecision; image-rendering:optimizeQuality; fill-rule:evenodd; clip-rule:evenodd"
            viewBox="0 0 118.14 98.13"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            xmlns:xodm="http://www.corel.com/coreldraw/odm/2003"
            class="hero"
        >
            <g id="Layer_x0020_1">
                <metadata id="CorelCorpID_0Corel-Layer" />
                <g id="_2586495438288">
                    <path
                        ref="heroBody"
                        class="hero_body"
                        fill="#D14023"
                        fill-rule="nonzero"
                        d="M97.55 38.07c-1.61,-2.47 -3.57,-4.79 -5.56,-7 -1.67,-1.84 -3.44,-3.6 -5.4,-5.17 -2.43,-1.96 -5.14,-3.57 -8.17,-4.57 -1.43,-0.48 -3.53,-0.73 -4.54,-1.89 -0.29,-0.34 -0.44,-0.76 -0.57,-1.17 -0.29,-0.96 -0.62,-2.01 -0.67,-3.02 -0.08,-1.81 2.05,-2.88 3.67,-3.37 1.44,-0.43 3.2,-0.34 4.7,-0.39 0.01,0 -0.2,-0.32 -0.24,-0.35 -2.61,-2.19 -6.41,-2.89 -9.31,-0.89 -0.27,-2.54 0.8,-5.23 3.62,-6.02 0.01,0 -0.18,-0.38 -0.27,-0.37 -2.96,0.26 -6.75,1.5 -7.83,4.25 -0.02,-1.22 -0.11,-2.45 -0.29,-3.66 -0.22,-1.41 -0.57,-3.67 -2.12,-4.45l-0.02 0c-2.41,2.03 -3.72,5.32 -3.65,8.3 0,0.17 0,0.55 0.09,0.79 -0.05,-0.08 -0.09,-0.16 -0.14,-0.22 -0.29,-0.4 -0.59,-0.77 -0.91,-1.14 -0.96,-1.09 -2.08,-2.09 -3.37,-2.84 -2.03,-1.16 -4.31,-1.48 -6.56,-0.73 -0.02,0 0.18,0.36 0.26,0.37 0.75,0.01 1.51,0.18 2.21,0.43 1.96,0.68 3.17,2.14 3.27,4.11 0.01,0.18 0.08,1.29 -0.08,1.36 -0.06,0.02 -0.37,-0.12 -0.44,-0.14 -0.75,-0.22 -1.51,-0.39 -2.28,-0.51 -1.96,-0.31 -4.18,-0.36 -5.85,0.78 -0.03,0.01 0.06,0.15 0.14,0.25 0.03,0.05 0.07,0.09 0.1,0.11l0.03 0.01c2.31,0.11 7.92,0.76 7.63,3.76 -0.12,1.21 -0.5,2.62 -1.34,3.57 -1.09,1.25 -2.88,1.96 -4.4,2.63 -3.63,1.6 -7.32,2.92 -10.61,5.08 -3.05,2 -5.73,4.48 -7.79,7.38 -5.91,8.29 -5.74,18.68 -3.02,28.01 2.35,8.11 6.89,16.62 15.64,20.13 2.85,1.15 5.93,1.67 9.01,1.76 2.27,0.07 4.51,-0.16 6.76,-0.27 0.98,-0.05 1.79,0.07 2.75,0.31 2.5,0.6 4.98,1.28 7.55,1.56 4.46,0.5 9.05,0.09 12.76,-2.32 3.12,-2.03 5.83,-4.64 8.23,-7.37 3.01,-3.42 5.59,-7.21 7.52,-11.26 3.93,-8.3 4.71,-17.8 -0.51,-25.83z"
                    />
                    <g ref="eyes">
                        <path
                            ref="eyeLeftWhite"
                            class="eye eye_left white"
                            fill="white"
                            fill-rule="nonzero"
                            d="M60.57 42.1c0.31,3.07 -1.3,5.75 -3.61,5.98 -2.3,0.24 -4.42,-2.07 -4.73,-5.14 -0.31,-3.07 1.3,-5.75 3.61,-5.99 2.3,-0.23 4.42,2.07 4.73,5.15z"
                        />
                        <path
                            ref="eyeRightWhite"
                            class="eye eye_right white"
                            fill="white"
                            fill-rule="nonzero"
                            d="M67.8 42.1c0.31,3.07 -1.3,5.75 -3.61,5.98 -2.3,0.24 -4.42,-2.07 -4.73,-5.14 -0.31,-3.07 1.3,-5.75 3.61,-5.99 2.3,-0.23 4.42,2.07 4.73,5.15z"
                        />
                        <path
                            ref="eyeRight"
                            class="eye eye_right"
                            fill="#282F39"
                            fill-rule="nonzero"
                            d="M63.39 39.2c-3.52,0 -3.53,5.47 0,5.47 3.52,0 3.53,-5.47 0,-5.47z"
                        />
                        <path
                            ref="eyeLeft"
                            class="eye eye_left"
                            fill="#282F39"
                            fill-rule="nonzero"
                            d="M55.73 39.78c-3.52,0 -3.53,5.47 0,5.47 3.52,0 3.53,-5.47 0,-5.47z"
                        />
                        <path
                            ref="eyeLeftBrow"
                            class="eye eye_left eyebrow"
                            fill="#282F39"
                            fill-rule="nonzero"
                            d="M55.45 32.88c-0.64,-0.44 -1.52,0.26 -2.05,0.62 -0.64,0.43 -1.31,0.93 -1.67,1.62 -0.22,0.41 0.22,0.87 0.63,0.64 0.63,-0.34 1.15,-0.85 1.77,-1.21 0.53,-0.3 1.41,-0.44 1.55,-1.1 0.05,-0.24 -0.03,-0.44 -0.23,-0.57z"
                        />
                        <path
                            ref="eyeRightBrow"
                            class="eye eye_right eyebrow"
                            fill="#282F39"
                            fill-rule="nonzero"
                            d="M64.45 32.5c1.12,0.5 2.52,1.7 2.52,3.02 0,0.24 -0.3,0.45 -0.51,0.29 -0.46,-0.33 -0.8,-0.78 -1.21,-1.17 -0.44,-0.42 -0.94,-0.7 -1.48,-0.97 -0.79,-0.39 -0.1,-1.51 0.68,-1.17z"
                        />
                    </g>
                    <path
                        ref="mouth"
                        class="mouth"
                        fill="#283039"
                        fill-rule="nonzero"
                        d="M72.43 60.51c0.32,-0.56 -0.61,-1.06 -0.98,-0.55 -1.71,2.32 -3.82,4.16 -6.57,5.12 -3.51,1.23 -6.52,0.39 -9.52,-1.61 -0.94,-0.63 -2,0.79 -1.06,1.38 3.33,2.07 6.4,3.08 10.3,1.92 3.35,-1 6.12,-3.22 7.83,-6.26z"
                    />
                    <g ref="legs">
                        <path
                            ref="legRight"
                            class="leg leg_right"
                            fill="#283039"
                            fill-rule="nonzero"
                            d="M90.6 88.18c-4,0.11 -9.09,0.72 -12.94,2.75 -0.53,-0.01 -1.03,0.02 -1.48,0.08 0.13,-2.2 -0.07,-4.51 -0.06,-6.55 0.02,-1.8 0.6,-5.07 -0.7,-6.52 -0.45,-0.5 -1.43,-0.4 -1.73,0.22 -0.01,0.03 -0.03,0.06 -0.04,0.09 -0.16,0.34 -0.15,0.7 0,1 0.7,2.25 0.22,5.41 0.25,7.71 0.02,1.66 0.12,7.16 0.47,7.31 0.03,0.11 17.89,1.99 19.6,2.13 2.39,0.21 7.84,0.21 7.9,-3.3 0.09,-4.8 -8.28,-5.01 -11.27,-4.92z"
                        />
                        <path
                            ref="legLeft"
                            class="leg leg_left"
                            fill="#283039"
                            fill-rule="nonzero"
                            d="M53.42 78.39c-1.6,4.43 -2.03,9.37 -1.68,14.08 -4.78,-1.75 -10.11,-1.91 -15.09,-1.14 -1.97,0.3 -8.67,0.98 -8.19,4.5 0.36,2.67 4.26,2.33 6.07,2.24 5.82,-0.27 11.82,-1.09 17.41,-2.73 0.26,-0.08 1.68,-0.64 1.73,-1.34 0.37,-5.15 0.08,-10.07 1.94,-15 0.51,-1.37 -1.7,-1.98 -2.19,-0.61z"
                        />
                    </g>
                    <g ref="arms">
                        <path
                            ref="armRight"
                            class="arm_right"
                            fill="#282F39"
                            fill-rule="nonzero"
                            d="M105.13 49.81c-3.84,-1.79 -9.1,-1.45 -12.17,1.63 -0.56,0.57 0.26,1.55 0.93,1.22 3.92,-1.87 7.86,-3.24 11.85,-0.51 1.72,1.17 3.14,2.93 3.88,4.87 0.63,1.66 0.28,3.51 0.93,5.09 0.02,0.07 0.07,0.12 0.11,0.18 -1.27,1.74 -2.25,4.62 -1.29,6.55 0.26,0.53 1.01,0.44 1.12,-0.15 0.21,-1.03 0.07,-2.08 0.36,-3.12 0.2,-0.73 0.53,-1.38 0.92,-2 0.23,0.56 0.49,1.1 0.69,1.62 0.41,1.11 0.69,2.34 1.33,3.34 0.25,0.38 0.98,0.21 0.98,-0.26 0.02,-1.18 -0.42,-2.36 -0.78,-3.47 -0.19,-0.59 -0.37,-1.27 -0.61,-1.92 0.46,0.2 0.88,0.47 1.32,0.87 0.91,0.8 1.56,1.81 2.39,2.67 0.46,0.49 1.21,0.09 1.02,-0.57 -0.65,-2.15 -3.8,-5.69 -6.35,-4.83 -0.5,-2.33 -0.2,-4.35 -1.59,-6.65 -1.19,-1.97 -2.95,-3.58 -5.04,-4.56z"
                        />
                        <path
                            ref="armLeft"
                            class="arm arm_left"
                            :class="{ say_hi: animationIs === 'sayHi' }"
                            fill="#282F39"
                            fill-rule="nonzero"
                            d="M28.6 52.61c-6.94,-1.18 -12.62,-3.89 -18.02,-7.49 -1.19,-0.66 -1.35,-1.22 -1.35,-1.22 -0.23,-2.47 -0.8,-5.16 -2.33,-6.99 -0.3,-0.37 -1.04,-0.08 -0.99,0.41 0.14,1.27 0.6,2.44 0.9,3.68 0.14,0.57 0.25,1.14 0.34,1.71 -1.41,-1.05 -2.82,-2.16 -4.24,-3.32 -0.67,-0.54 -1.48,0.28 -0.95,0.94 1.32,1.63 2.83,3.11 4.47,4.46 -1.89,0.34 -3.77,0.2 -5.85,0.02 -0.6,-0.05 -0.79,0.84 -0.3,1.11 2.34,1.28 5.2,1.19 7.76,0.42 0.16,0.07 0.34,0.11 0.52,0.09 5.79,4.11 12.86,6.75 19.5,8.13 1.29,0.27 1.81,-1.73 0.54,-1.95z"
                        />
                    </g>
                </g>
            </g>
        </svg>
    </transition>
</template>
<script>
import { gsap } from "gsap";
export default {
    name: "Pomegranate",

    props: {
        size: {
            type: Number,
            required: false,
            default: 90,
        },
        animationIs: {
            type: String,
            required: false,
            default: null,
        },
    },
    methods: {
        onEnter() {
            if (!this.animationIs) return;
            if (this.animationIs === "squeeze") {
                this.squeeze();
                return;
            } else if (this.animationIs === "jump") {
                this.jump();
                return;
            } else if (this.animationIs === "sad") {
                this.sad();
                return;
            }
        },

        squeeze() {
            var tl = gsap.timeline({ repeat: -1, repeatDelay: 4 });
            gsap.set(this.$refs.heroBody, {
                transformStyle: "preserve-3d",
            });
            tl.to(this.$refs.eyes, {
                y: -10,
                x: -10,
                scale: 2,
                duration: 0.5,
                ease: "bounce.out",
                z: 30,
            })
                .to(this.$refs.pomegranate, { rotationY: 35, duration: 0.1 }, 0)
                .to(this.$refs.arms, { scale: 0.8, x: 5, duration: 0 }, 0)
                .to(
                    this.$refs.eyes,
                    { y: 0, x: 0, scale: 1, duration: 0.3 },
                    0.7
                )
                .to(
                    this.$refs.pomegranate,
                    { rotationY: 0, skewX: 0, scaleX: 1, duration: 0.8 },
                    0.7
                )
                .to(this.$refs.arms, { scale: 1, x: 0, duration: 0.1 }, 0.7);
        },
        jump() {
            var tl = gsap.timeline({ repeat: -1, repeatDelay: 0.2 });
            gsap.set(this.$refs.legLeft, {
                transformOrigin: "right top",
            });
            gsap.set(this.$refs.armLeft, {
                transformOrigin: "right",
                rotate: 0,
            });

            tl.to(
                this.$refs.legRight,
                {
                    rotate: 35,
                    duration: 0.3,
                },
                0
            )
                .to(
                    this.$refs.legLeft,
                    {
                        rotate: -25,
                        duration: 0.3,
                    },
                    0
                )
                .to(
                    this.$refs.armLeft,
                    {
                        rotate: -70,
                        y: 20,
                        x: -5,
                        duration: 0.4,
                    },
                    0
                )
                .to(
                    this.$refs.armRight,
                    {
                        rotate: 70,
                        x: 10,
                        duration: 0.4,
                    },
                    0
                )
                .to(
                    this.$refs.pomegranate,
                    {
                        y: -50,
                        duration: 0.8,
                        rotateX: 30,
                        scaleX: 1.2,
                        scaleY: 0.8,
                    },
                    0
                )
                .to(
                    this.$refs.legLeft,
                    {
                        rotate: 0,
                        duration: 0.3,
                    },
                    0.7
                )
                .to(
                    this.$refs.legRight,
                    {
                        rotate: 0,
                        duration: 0.3,
                    },
                    0.7
                )
                .to(
                    this.$refs.armLeft,
                    {
                        rotate: 0,
                        x: 0,
                        y: 0,
                        duration: 0.4,
                    },
                    0.7
                )
                .to(
                    this.$refs.armRight,
                    {
                        rotate: 0,
                        x: 0,
                        duration: 0.4,
                    },
                    0.7
                )
                .to(
                    this.$refs.pomegranate,
                    {
                        y: 0,
                        duration: 0.3,
                        rotateX: 0,
                        scaleX: 1,
                        scaleY: 1,
                    },
                    0.7
                );
        },
        sad() {
            var tl = gsap.timeline({ repeat: -1 });
            gsap.set(this.$refs.pomegranate, { transformStyle: "preserve-3d" });

            gsap.set(this.$refs.mouth, {
                rotate: "180deg",
                translateX: 20,
                translateY: 10,
            });

            gsap.set(this.$refs.armLeft, {
                rotate: "300deg",
                translateY: 40,
                translateX: 0,
            });
        },
    },
};
</script>

<style scoped>
* {
    overflow: visible;
}

.say_hi {
    z-index: 0;
    transform-origin: bottom right;
    transform: rotate(10deg) translateX(-10px) translateY(20px);
    animation: hi infinite 5s ease-in-out;
}

@keyframes hi {
    0% {
        transform: rotate(10deg) translateX(-10px) translateY(10px);
    }

    5% {
        transform: rotate(40deg) translateX(-15px) translateY(60px);
    }

    10% {
        transform: rotate(10deg) translateX(-10px) translateY(10px);
    }

    90% {
        transform: rotate(10deg) translateX(-10px) translateY(10px);
    }

    95% {
        transform: rotate(40deg) translateX(-15px) translateY(60px);
    }

    100% {
        transform: rotate(10deg) translateX(-10px) translateY(10px);
    }
}
</style>
